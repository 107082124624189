<template>
  <div class="d-flex flex-column align-items-center justify-content-center p-5">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
    <div class="mt-3 text-center">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'LoadingMessage',
  props: {
    message: {
      type: String,
    },
  },
  computed: {
    text() {
      return this.message && this.message.length ? this.message : 'Loading';
    },
  },
};
</script>
